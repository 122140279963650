import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://res.cloudinary.com/dyjzfdguj/image/upload/v1727910793/IMG_0982_papgjo.jpg" className="App-logo" alt="logo" />
        <p>
          Oh, he's mad.<br/>This post has been fact checked by true American patriots.
        </p>
      </header>
    </div>
  );
}

export default App;
